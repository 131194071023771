export const TodoData = [
  {
    id: 1,
    text: "Complete online JavaScript course",
    complete: true,
  },
  {
    id: 2,
    text: "Jog around the park 3x",
    complete: false,
  },
  {
    id: 3,
    text: "10 minutes meditation",
    complete: false,
  },
  {
    id: 4,
    text: "Read for 1 hour",
    complete: false,
  },
  {
    id: 5,
    text: "Pick up groceries",
    complete: false,
  },
  {
    id: 6,
    text: "Complete Todo App on Frontend Mentor",
    complete: false,
  },
];
